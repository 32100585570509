<script>
import {
  ArrowUpIcon,
  UserIcon,
  UsersIcon,
  MailIcon,
  MonitorIcon,
  PhoneIcon,
  BookIcon,
  MessageCircleIcon,
  AirplayIcon,
  LinkIcon,
  CpuIcon,
  DollarSignIcon,
  MapPinIcon,
  HexagonIcon,
  FigmaIcon,
  CropIcon,
  CheckIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "vue-feather-icons";

import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Skeleton from "@/view/front/components/skeleton";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import moment from "moment";

/**
 * Page-job-comapany component
 */
export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      title: this.$route.params.title.replaceAll("-", " "),
      data: {},
      view_more: false,
      jobs: [],
      // search_text: "",
      page_num: 1,
      loading: false,
      show_load_more: false,
      // applied: null,
      take: 0,
      more: false,
      detail: null,

      applied: [],
      saveJobSuccess: {},
      savejobbtnclicked: [],
      search_text: "",
      selected_cities:
        typeof this.$router.history.current.params.selected_cities !==
        "undefined"
          ? this.$router.history.current.params.selected_cities
          : [],
      description_search:
        this.$router.history.current.params.description_search,
    };
  },

  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    UserIcon,
    // UsersIcon,
    MailIcon,
    // MonitorIcon,
    PhoneIcon,
    BookIcon,
    MessageCircleIcon,
    MapPinIcon,
    CheckIcon,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    Skeleton,
  },
  mounted() {
    this.load();
  },

  methods: {
    moment: function (date) {
      return moment(date);
    },
    load() {
      axios
        .post(`${API_URL}/get-company-profile`, {
          company_name: this.title,
          // take: this.take + 2,
        })
        .then((response) => {
          this.data = response.data;
          this.jobs = response.data.jobs;
          this.more = response.data.more;

          const applied = this.applied;
          const token = this.token;
          $.each(this.jobs, function (index, item) {
            // console.log("Umer");
            axios.defaults.headers.common["Authorization"] = token;
            axios
              .post(`${API_URL}/save-check-applied-job`, {
                job_id: item.id,
              })
              .then((res) => {
                if (res.data == true) {
                  applied[item.id] = true;
                } else {
                  applied[item.id] = false;
                }
              });
          });
          const saveJobSuccess = this.saveJobSuccess;
          $.each(this.jobs, function (index, item) {
            axios.defaults.headers.common["Authorization"] = token;
            axios
              .post(`${API_URL}/already-save-job`, {
                job_id: item.id,
              })
              .then((response) => {
                // console.log(response);
                saveJobSuccess[item.id] = response.data.msg;
              });
          });
        });
    },
    goToCity(item) {
      this.$router.replace({
        path: "/jobs/Pakistan/" + item,
        params: {
          search_text: this.search_text,
          selected_cities: [{ id: 1, value: item }],
        },
      });
    },
    loadMore() {
      this.load();
    },
    // search() {
    //   axios
    //     .post(`${this.apiUrl}/search-jobs`, {
    //       search_text: this.search_text,
    //     })
    //     .then((response) => {
    //       console.log("RES", response.data);
    //       console.log("data: ", this.data);
    //       this.data = response.data;
    //       console.log("new data: ", this.data);
    //       // this.loading = false;
    //       // if (this.data.length < 12) {
    //       //   this.show_load_more = false;
    //       // } else {
    //       //   this.show_load_more = true;
    //       // }
    //     });
    // },
    search() {
      axios
        .post(`${this.apiUrl}/search-jobs`, {
          search_text: this.search_text,
        })
        .then((response) => {
          console.log("data: ", this.data);
          this.jobs = response.data;
          console.log("myCitiesArray =>", this.myCitiesArray);
          console.log("new data: ", this.data);
          // if (this.city.length > 0) {
          //   this.myCitiesArray = [];
          //   this.city.map((c) => {
          //     this.myCitiesArray.push(c.value);
          //   });
          //   // filter by cities
          //   this.jobs = response.data.filter((job) =>
          //     job.location_city.some((r) => this.myCitiesArray.includes(r))
          //   );
          // } else {
          //   this.jobs = response.data;
          // }

          // this.loading = false;
          // if (this.data.length < 12) {
          //   this.show_load_more = false;
          // } else {
          //   this.show_load_more = true;
          // }
        });
    },
    singleDetail(item) {
      this.detail = item;
    },
    saveJob(item) {
      // this.savejobbtnclicked[item.id] = response.data.msg;
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/save-job`, {
          job_id: item.id,
        })
        .then((response) => {
          this.saveJobSuccess[item.id] = response.data.msg;
          this.savejobbtnclicked[item.id] = response.data.msg;
          setTimeout(() => {
            this.savejobbtnclicked[item.id] = "";
          }, 3000);
        });
    },
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <Skeleton v-if="loading" class="pt-20" />
    <button type="button" @click="loadmore" v-if="show_load_more"></button>
    <!-- Company Detail Start by ch-->
    <div class="section_ch_p mt-15">
      <div class="row co_page_hero">
        <div class="row container" style="margin: 0 auto">
          <div class="col-lg-2 col-4 pl-0">
            <img
              :src="mediaUrl + data.profile_pic"
              class="co_profile_pic"
              alt="..."
            />
          </div>
          <div class="col-lg-6 col-12 hide_on_mobile_ch">
            <div class="postion_abslout p-8 w-100">
              <h4 class="co_heading text-white">{{ data.company_name }}</h4>
              <div class="h6 text-white" v-if="data.industries">
                {{ data.industries.join(", ") }}
              </div>
              <div class="row">
                <div class="h6 text-white col-lg-6">
                  <map-pin-icon
                    class="fea icon-sm text-white mr-2"
                    style="color: white !important"
                  ></map-pin-icon>
                  {{
                    data.branches && data.branches.length > 0
                      ? data.branches[0].branchAddress
                      : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12" style="position: relative">
            <div class="co_discription_by_ch">
              <ul class="list-unstyled mb-0 postion_abslout py-8">
                <li class="h6 text-white">
                  <!-- <i class="mdi mdi-office-building text-warning"></i> -->
                  <img
                    class="mr-2"
                    src="/images/co_page/Established.png"
                    alt=""
                  />
                  <span class="text-white">Year founded : </span>
                  {{ data.established_in }}
                </li>
                <li class="h6 text-white">
                  <img
                    class="mr-2"
                    src="/images/co_page/No.ofemployees.png"
                    alt=""
                  />
                  <span class="text-white">Employees :</span>
                  {{ data.employees }}
                </li>
                <li class="h6 text-white">
                  <img class="mr-2" src="/images/co_page/Website.png" alt="" />
                  <span class="text-white"></span>
                  {{ data.website }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row hide_by_ch">
        <div class="col-lg-6 col-12">
          <div class="py-8 w-100">
            <h4 class="co_heading">{{ data.company_name }}</h4>
            <div class="h6" v-if="data.industries">
              {{ data.industries.join(", ") }}
            </div>
            <div class="row">
              <div class="h6 col-lg-6">
                <map-pin-icon class="fea icon-sm mr-2" style=""></map-pin-icon>
                {{
                  data.branches && data.branches.length > 0
                    ? data.branches[0].branchAddress
                    : "-"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row container" style="margin: 0 auto">
        <div class="col-lg-2 col-6 pl-0 pr-0 text-white follow_button_co">
          Follow
        </div>
        <div class="col-lg-4 col-6 follwer_posts_button_co">
          <div class="p-3">
            <a href="#jobs">{{ this.jobs.length }} Jobs Posted</a>
          </div>
        </div>
        <div class="col-lg-4"></div>
      </div>
      <div class="row container mt-10" style="margin: 0 auto">
        <div class="col-lg-12 pt-3 pb-3 pl-10 pr-10 bg-white mb-10">
          <!-- <h4 class="mt-lg-5 mt-0 profile_vision_text">Company vision</h4>
          <p
            class="mb-0"
            v-if="view_more === false && data.vision_statement"
            v-html="data.vision_statement.substring(0, 850)"
          ></p>
          <p class="mb-0" v-if="!data.vision_statement">
            No vision statement avilable
          </p>
          <p
            class="mb-0"
            v-if="view_more === true"
            v-html="data.vision_statement"
          ></p>
          <button
            class="btn btn-sm follw_btn"
            v-if="
              data.vision_statement &&
              view_more === false &&
              data.vision_statement.length > 860
            "
            @click="view_more = true"
          >
            view more
          </button>
          <button
            class="btn btn-sm follw_btn"
            v-if="
              data.vision_statement &&
              view_more === true &&
              data.vision_statement.length > 860
            "
            @click="view_more = false"
          >
            view less
          </button> -->
          <!-- <h3 class="mt-lg-5 mt-4 profile_vision_text">
            Mission <span style="font-weight: bold">statement</span>
          </h3>
          <p
            class="mt-6 mb-6"
            v-if="data.mission_statement"
            v-html="data.mission_statement"
          ></p>
          <p class="mb-0" v-if="!data.mission_statement">
            No mission statement avilable
          </p> -->
          <h3 class="mt-lg-5 mt-4 profile_vision_text">
            About <span style="font-weight: bold">company</span>
          </h3>
          <p
            class="mt-6 mb-0"
            v-if="data.about_company"
            v-html="data.about_company"
          ></p>
        </div>
      </div>
    </div>

    <div class="container" id="jobs" style="margin: 0 auto">
      <!-- row -->
      <div class="row pagech1 p-0 m-0 pt-4 pb-4">
        <div class="col-lg-12 pb-2 co_by_CH">
          <div class="col-lg-12 p-0 job_listing_header">
            <div class="simple-heading">
              JOB LISTING

              <span class="pull-right">
                <div class="navbar-form" role="search">
                  <div class="input-group stylish-input-group">
                    <input
                      type="text"
                      class="form-control serach_i_ch"
                      placeholder="Search"
                      v-model="search_text"
                      @keyup.enter="search()"
                    />
                    <span class="input-group-addon">
                      <button
                        type="button"
                        @click="search()"
                        class="ch_btn_submit"
                      >
                        <span class="fa fa-search"></span>
                      </button>
                    </span>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-7 co_by_CH">
          <!-- col 12 start -->
          <div
            class="col-lg-12 row mx-0 col-md-4 col-12 pl-0 pr-0 job_list_d"
            v-for="(item, index) in jobs"
            :key="index + 'h'"
            :style="index > 0 ? 'margin-top: 2%' : 'margin-top: 0%'"
          >
            <div
              class="card overflow-hidden ch-box"
              :style="
                item.urgent == 1 ? 'background: #ffc6c638 !important' : ''
              "
            >
              <div class="position position_style_ch row">
                <router-link
                  :to="
                    '/jobs-careers/pakistan/' +
                    item.location_city.map((v) => v.toLowerCase()).join('-') +
                    '/' +
                    item.job_title
                      .toLowerCase()
                      .replace(/ /g, '-')
                      .replace(/[^\w-]+/g, '') +
                    '/' +
                    item.id
                  "
                  class="text-dark col-lg-10 col-8 title_job_by_ch"
                  >{{ item.job_title }}
                  <span class="job_function">({{ item.job_function }})</span>
                </router-link>
                <div
                  class="col-lg-2 col-4 pr-0 Urgent_hiring"
                  style="text-align: end"
                >
                  <img
                    v-if="item.feature == 1"
                    src="/images/job_page/Featured_new.svg"
                    alt="..."
                  />
                </div>
                <div class="co_name col-lg-9 col-8">
                  <router-link
                    style="font-size: 15px !important"
                    :to="
                      '/employer/' +
                      item.recruiter.company_name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '')
                    "
                    class="company-name"
                  >
                    {{ item.recruiter.company_name }} </router-link
                  ><span>
                    <img src="/images/job_page/Verified.svg" alt="..." />
                  </span>
                </div>
                <div
                  class="col-lg-3 col-4 pr-0 pl-0 Urgent_hiring"
                  style="text-align: end"
                >
                  <img
                    v-if="item.urgent == 1"
                    src="/images/job_page/Urgent_hiring.svg"
                    alt="..."
                  />
                </div>
                <div class="col-lg-12 col-8 salary_style pl-3 pt-1">
                  <img
                    class="pr-2"
                    src="/images/job_page/Money.svg"
                    alt="..."
                  />{{ item.salary_min }} - {{ item.salary_max }}/Month
                </div>
                <div
                  class="
                    vew_details_btn
                    col-lg-3 col-4
                    pr-0
                    pl-0
                    pb-2
                    pt-2
                    hide_on_dectop_ch
                  "
                >
                  <router-link
                    :to="
                      '/jobs-careers/pakistan/' +
                      item.location_city.map((v) => v.toLowerCase()).join('-') +
                      '/' +
                      item.job_title
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '') +
                      '/' +
                      item.id
                    "
                    ><button>
                      <img
                        class="pr-1"
                        src="/images/job_page/View.png"
                        alt="..."
                      />
                      View Details
                    </button></router-link
                  >
                </div>
                <div class="col-12 hide_on_dectop_ch pl-3">
                  <div class="media-body d-flex">
                    <h4 class="widget-title tooltipch mb-0 bold detail_heading">
                      <img
                        class="pr-2"
                        src="/images/job_page/Due_date.svg"
                        alt="..."
                      />
                      <span class="tooltiptextch">Application deadline</span>
                    </h4>
                    <p
                      class="text-primary mb-0 mb-0 detail_body"
                      style="color: #a82511 !important"
                    >
                      {{ moment(item.apply_by).format("MMMM Do YYYY") }}
                    </p>
                  </div>
                </div>
                <div class="row pb-2 mx-0 pr-0">
                  <div class="location_perant_div col-lg-9">
                    <span>
                      <img
                        class="pr-2 location_icon_ch"
                        src="/images/job_page/Location.svg"
                        alt="..."
                      />
                    </span>
                    <div
                      @click="goToCity(_item)"
                      class="lo_name locations_style pl-1 pr-0 pt-2 d-flex"
                      v-for="(_item, _index) in item.location_city"
                      :key="_index + 'i'"
                      style="cursor: pointer"
                    >
                      <span class="p-0 d-flex" v-if="_index < 4">
                        <span
                          class="p-0"
                          v-if="
                            _index > 0 && _index < item.location_city.length
                          "
                          style=""
                          >&nbsp;,&nbsp;</span
                        >

                        {{ _item }}
                      </span>
                      <span v-if="_index === 3" class="pt-0">
                        , more location</span
                      >
                      <!-- </div> -->
                    </div>
                  </div>
                  <div
                    class="vew_details_btn col-lg-3 pr-0 pb-2 hide_on_mobile_ch"
                    v-on:click="singleDetail(item)"
                  >
                    <button>
                      <img
                        class="pr-1"
                        src="/images/job_page/View.png"
                        alt="..."
                      />
                      View Details
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-12 pl-0 pt-3 d-flex job_description_style"
                style="max-height: 85px; overflow: hidden"
              >
                <p v-html="item.job_description"></p>
              </div>
            </div>
          </div>
          <div
            class="col-lg-12 row mx-0 col-md-4 col-12 pl-0 pr-0 job_list_m"
            v-for="(item, index) in jobs"
            :key="index + 'j'"
            :style="index > 0 ? 'margin-top: 2%' : 'margin-top: 0%'"
          >
            <div
              class="card overflow-hidden ch-box"
              :style="
                item.urgent == 1 ? 'background: #ffc6c638 !important' : ''
              "
            >
              <div class="position position_style_ch row">
                <router-link
                  :to="
                    '/jobs-careers/pakistan/' +
                    item.location_city.map((v) => v.toLowerCase()).join('-') +
                    '/' +
                    item.job_title
                      .toLowerCase()
                      .replace(/ /g, '-')
                      .replace(/[^\w-]+/g, '') +
                    '/' +
                    item.id
                  "
                  class="text-dark col-lg-10 col-8 title_job_by_ch"
                  >{{ item.job_title }}
                </router-link>
                <div class="col-lg-2 col-4 pr-0 vew_details_btn m-0">
                  <router-link
                    :to="
                      '/jobs-careers/pakistan/' +
                      item.location_city.map((v) => v.toLowerCase()).join('-') +
                      '/' +
                      item.job_title
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '')+'/' +item.id
                    "
                    ><button class="py-0" style="font-weight: 400">
                      Details >>
                    </button></router-link
                  >
                </div>
                <div class="col-8 m-0 py-0">
                  <span class="job_function">{{ item.job_function }}</span>
                </div>
                <div
                  class="col-4 pr-0 m-0 Urgent_hiring vew_details_btn"
                  style="text-align: end"
                >
                  <img
                    v-if="item.feature == 1"
                    src="/images/job_page/Featured_new.svg"
                    alt="..."
                  />
                </div>
                <div class="co_name col-lg-9 col-8">
                  <span>
                    <img src="/images/job_page/Verified_m.svg" alt="..." />
                  </span>
                  <router-link
                    style="font-size: 15px !important"
                    :to="
                      '/employer/' +
                      item.recruiter.company_name
                        .toLowerCase()
                        .replace(/ /g, '-')
                        .replace(/[^\w-]+/g, '')
                    "
                    class="company-name"
                  >
                    {{ item.recruiter.company_name }}
                  </router-link>
                </div>
                <div
                  class="col-lg-3 col-4 pr-0 pl-0 Urgent_hiring"
                  style="text-align: end"
                >
                  <img
                    v-if="item.urgent == 1"
                    src="/images/job_page/Urgent_hiring.svg"
                    alt="..."
                  />
                </div>
                <div class="col-lg-12 col-8 salary_style pl-3 pt-1">
                  <img
                    class="pr-2"
                    src="/images/job_page/Money.svg"
                    alt="..."
                  />{{ item.salary_min }} - {{ item.salary_max }}/Month
                </div>

                <div class="col-12 hide_on_dectop_ch pl-3">
                  <div class="media-body d-flex pt-2">
                    <h4 class="widget-title tooltipch mb-0 bold detail_heading">
                      <img
                        class="pr-2"
                        src="/images/job_page/Due_date.svg"
                        alt="..."
                      />
                      <span class="tooltiptextch">Application deadline</span>
                    </h4>
                    <p
                      class="text-primary mb-0 mb-0 detail_body"
                      style="color: #a82511 !important"
                    >
                      {{ moment(item.apply_by).format("MMMM Do YYYY") }}
                    </p>
                  </div>
                </div>
                <div class="row pb-2 mx-0 pr-0">
                  <div class="location_perant_div col-lg-9">
                    <span>
                      <img
                        class="pr-2 location_icon_ch"
                        src="/images/job_page/Location.svg"
                        alt="..."
                      />
                    </span>
                    <div
                      @click="goToCity(_item)"
                      class="lo_name locations_style pl-1 pr-0 pt-2 d-flex"
                      v-for="(_item, _index) in item.location_city"
                      :key="_index + 'q'"
                      style="cursor: pointer"
                    >
                      <span class="p-0 d-flex" v-if="_index < 4">
                        <span
                          class="p-0"
                          v-if="
                            _index > 0 && _index < item.location_city.length
                          "
                          style=""
                          >&nbsp;,&nbsp;</span
                        >

                        {{ _item }}
                      </span>
                      <span v-if="_index === 3" class="pt-0">
                        , more location</span
                      >
                      <!-- </div> -->
                    </div>
                  </div>
                  <div
                    class="vew_details_btn col-lg-3 pr-0 pb-2 hide_on_mobile_ch"
                    v-on:click="singleDetail(item)"
                  >
                    <button>
                      <img
                        class="pr-1"
                        src="/images/job_page/View.png"
                        alt="..."
                      />
                      View Details
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-12 pl-0 pt-3 d-flex job_description_style"
                style="max-height: 85px; overflow: hidden"
              >
                <p v-html="item.job_description"></p>
              </div>
            </div>
          </div>
          <!-- col 12 end -->
        </div>
        <div class="col-lg-5 pl-0 pr-0 sidbar_ch_new" v-if="detail">
          <div class="card sidebar mr-4" style="background: #f7f7f7">
            <div class="card-body widget border-bottom cardbodych" style="">
              <h5 class="mb-0 bold position_style_ch border-0">
                {{ detail.job_title }}
                <!-- <span class="application_count"
                            >(Applicants:
                            {{ detail.job_application_count }})</span
                          > -->
                <p class="company-name mb-0" style="font-size: 15px !important">
                  {{ detail.recruiter.company_name }}
                  <span>
                    <img src="/images/job_page/Verified.svg" alt="..." />
                  </span>
                </p>
              </h5>
              <button
                class="badge badge-danger badge-pill"
                @click="detail = false"
              >
                X
              </button>
            </div>

            <div class="job_details_btn pl-2 pr-2 pt-2 pb-0 border-bottom">
              <router-link
                v-if="auth && !applied[detail.id]"
                :to="
                  '/jobs-careers/apply/pakistan/' +
                  detail.location_city.map((v) => v.toLowerCase()).join('-') +
                  '/' +
                  detail.job_title
                    .toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '') +'/' + detail.id
                "
                class="m-1 mt-1"
                ><img src="/images/job_page/Apply.svg" alt="..."
              /></router-link>
              <button type="button" v-if="!auth" class="m-1">
                <a href="/login">
                  <img src="/images/job_page/Apply.png" alt="..." />
                </a>
              </button>
              <!-- <router-link
                v-if="auth && applied[detail.id]"
                :to="
                  '/jobs-careers/apply/pakistan/' +
                  detail.location_city.map((v) => v.toLowerCase()).join('-') +
                  '/' +
                  detail.job_title
                    .toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '')
                "
                class="m-1"
                ><img src="/images/job_page/Applied.png" alt="..."
              /></router-link> -->
              <button
                type="button"
                v-if="auth && applied[detail.id]"
                @click="saveJob(detail)"
                class="m-1"
              >
                <img src="/images/job_page/Applied.png" alt="..." />
              </button>
              <button
                type="button"
                v-if="auth && !saveJobSuccess[detail.id]"
                @click="saveJob(detail)"
                class="m-1"
              >
                <img src="/images/job_page/Save.png" alt="..." />
              </button>
              <button type="button" v-if="!auth" class="m-1">
                <a href="/login">
                  <img src="/images/job_page/Save.png" alt="..." />
                </a>
              </button>
              <button
                type="button"
                v-if="auth && saveJobSuccess[detail.id]"
                class="m-1"
              >
                <img src="/images/job_page/Saved.svg" alt="..." />
              </button>

              <button type="button" class="m-1">
                <img src="/images/job_page/Invite_Friends.svg" alt="..." />
              </button>

              <!-- <button
                          type="button"
                          @click="detail = false"
                          class="btn btn-sm sidebar_btns_cls btn_deteil_tns m-1"
                        >
                          Close
                        </button> -->
              <div class="icons">
                <ul class="list-unstyled head mb-0">
                  <ul class="list-unstyled social-icon">
                    <!-- style="text-align: right !important" -->
                    <ShareNetwork
                      network="twitter"
                      url="https://news.vuejs.org/issues/180"
                      title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                      description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                      quote="The hot reload is so fast it\'s near instant. - Evan You"
                      hashtags="job4u_pk"
                      twitterUser="youyuxi"
                    >
                      <li
                        class="list-inline-item ml-1"
                        style="margin-right: 0%"
                      >
                        <a
                          href="javascript:void(0)"
                          class="rounded"
                          style="border: none"
                          title="Share on Twitter"
                        >
                          <twitter-icon
                            class="fea icon-xs fea-social"
                          ></twitter-icon>
                        </a>
                      </li>
                    </ShareNetwork>

                    <ShareNetwork
                      network="facebook"
                      url="https://news.vuejs.org/issues/180"
                      title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                      description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                      quote="The hot reload is so fast it\'s near instant. - Evan You"
                      hashtags="job4u_pk"
                    >
                      <li class="list-inline-item" style="margin-right: 0%">
                        <a
                          href="javascript:void(0)"
                          class="rounded"
                          style="border: none"
                          title="Share on Facebook"
                        >
                          <facebook-icon
                            class="fea icon-sm fea-social"
                          ></facebook-icon>
                        </a>
                      </li>
                    </ShareNetwork>
                    <ShareNetwork
                      network="linkedin"
                      url="https://news.vuejs.org/issues/180"
                      title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                      description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                      quote="The hot reload is so fast it\'s near instant. - Evan You"
                      hashtags="job4u_pk"
                    >
                      <li class="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          class="rounded"
                          style="border: none"
                          title="Share on LinkedIn"
                        >
                          <linkedin-icon
                            class="fea icon-sm fea-social"
                          ></linkedin-icon>
                        </a>
                      </li>
                    </ShareNetwork>
                  </ul>
                </ul>
              </div>
            </div>

            <div class="card-body cardbodych2 pt-0" style="overflow: auto">
              <div class="row" style="margin-top: 1%">
                <div class="border-bottom pl-4 pr-4 pb-2">
                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Time_shift.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Job shift</span>
                          </h4>
                          <p
                            class="text-primary mb-0 detail_body"
                            v-for="(_item, _index) in detail &&
                            detail.job_shift"
                            :key="_index"
                          >
                            <span
                              v-if="
                                _index > 0 && _index < detail.job_shift.length
                              "
                              style=""
                              >,</span
                            >
                            {{ _item }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Time_shift.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Employment type</span>
                          </h4>
                          <span
                            class="text-primary mb-0 detail_body"
                            v-for="(_item, _index) in detail && detail.job_type"
                            :key="_index"
                          >
                            {{ _item }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Money_left.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Per month salary</span>
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            +{{ detail && detail.salary_min }} to
                            {{ detail && detail.salary_max }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              mb-0
                              tooltipch
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Sector.svg"
                              alt="..."
                              width="25"
                            />
                            <span class="tooltiptextch">Job function</span>
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            {{ detail && detail.job_function }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Applicants.svg"
                              alt="..."
                              width="25"
                            />
                            <span class="tooltiptextch"
                              >Total applicants to date</span
                            >
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            <span v-if="detail.job_application_count < 50">
                              Less than 50
                            </span>
                            <span v-if="detail.job_application_count > 50">
                              More than 50
                            </span>
                            <!-- {{ detail.job_application_count }} -->
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col col-md-6">
                      <div
                        class="media widget align-items-center"
                        style="margin-top: 5%"
                      >
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/No._of_vacancies.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch"
                              >Number of vacancies</span
                            >
                          </h4>
                          <span class="text-primary mb-0 detail_body">
                            {{ detail.no_of_vacancies }} Vacancies
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Education_required.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch"
                              >Minimum qualification</span
                            >
                          </h4>
                          <p class="text-primary mb-0 detail_body">
                            {{ detail && detail.education }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Gender_preference.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Gender preference</span>
                          </h4>
                          <p
                            class="text-primary mb-0 detail_body"
                            v-for="(_item, _index) in detail && detail.gender"
                            :key="_index"
                          >
                            <span
                              v-if="_index > 0 && _index < detail.gender.length"
                              style=""
                              >/</span
                            >{{ _item }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Date_posted.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Job post date</span>
                          </h4>
                          <p class="text-primary mb-0 mb-0 detail_body">
                            {{
                              moment(detail.created_at).format("MMMM Do YYYY")
                            }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col col-md-6">
                      <div class="media widget align-items-center mt-3">
                        <div class="media-body d-flex">
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Due_date.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch"
                              >Application deadline</span
                            >
                          </h4>
                          <p class="text-primary mb-0 mb-0 detail_body">
                            {{ moment(detail.apply_by).format("MMMM Do YYYY") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 1%">
                    <div class="col col-md-12">
                      <div class="media widget align-items-center mt-3">
                        <div
                          class="media-body location_ch"
                          style="display: -webkit-box"
                        >
                          <h4
                            class="
                              widget-title
                              tooltipch
                              mb-0
                              bold
                              detail_heading
                            "
                          >
                            <img
                              class="pr-2"
                              src="/images/job_page/Location_left.svg"
                              alt="..."
                            />
                            <span class="tooltiptextch">Job locations</span>
                          </h4>
                          <div class="row">
                            <p
                              class="col col-md-3 text-primary mb-0 detail_body"
                              style="display: contents"
                            >
                              <span
                                v-for="(_item, _index) in detail &&
                                detail.location_city"
                                :key="_index"
                                style="display: inline-flex"
                              >
                                <span
                                  v-if="
                                    _index > 0 &&
                                    _index < detail.location_city.length
                                  "
                                  style=""
                                  >&nbsp;,&nbsp;</span
                                >
                                {{ _item }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-md-12">
                  <div class="media widget align-items-center mt-3">
                    <div class="media-body">
                      <h4 class="widget-title mb-0 bold detail_heading">
                        Job Description
                      </h4>
                      <p
                        id="temp"
                        class="para-desc mx-auto text-muted detail_body"
                        v-html="detail.job_description"
                      ></p>
                      <!-- <p id="temp1" class="text-primary mb-0 mb-0"></p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- job listing end -->
    <!-- Company Detail Start -->
    <section class="section d-none">
      <div class="container">
        <div class="row">
          <div class="row" style="margin-top: 2%">
            <div class="col-lg-5 col-md-9">
              <div class="row" style="margin-left: -42.5px">
                <div class="col-lg-12 col-md-7 col-12">
                  <div
                    class="col-lg-12 col-md-4 col-12"
                    v-for="(item, index) in jobs"
                    :key="index"
                    :style="index > 0 ? 'margin-top: 2%' : 'margin-top: 0%'"
                  >
                    <div
                      class="
                        card
                        job-box
                        rounded
                        border-0
                        overflow-hidden
                        d-none
                      "
                    >
                      <div class="border-bottom">
                        <div class="position-relative">
                          <img
                            :src="mediaUrl + item.recruiter.banner"
                            class="img-fluid"
                            alt=""
                            style="height: 198px; width: 100%"
                          />
                          <div class="job-overlay bg-white"></div>
                        </div>
                        <h5 class="mb-0 position">
                          <router-link
                            :to="
                              '/jobs-careers/pakistan/' +
                              item.location_city
                                .map((v) => v.toLowerCase())
                                .join('-') +
                              '/' +
                              item.job_title
                                .toLowerCase()
                                .replace(/ /g, '-')
                                .replace(/[^\w-]+/g, '')+'/' +item.id
                            "
                            class="text-dark"
                            >{{ item.job_title }}</router-link
                          >
                          <ul class="list-unstyled h6 mb-0 text-warning">
                            <li class="list-inline-item mb-0">
                              <i class="mdi mdi-star"></i>
                            </li>
                            <li class="list-inline-item mb-0">
                              <i class="mdi mdi-star"></i>
                            </li>
                            <li class="list-inline-item mb-0">
                              <i class="mdi mdi-star"></i>
                            </li>
                            <li class="list-inline-item mb-0">
                              <i class="mdi mdi-star"></i>
                            </li>
                            <li class="list-inline-item mb-0">
                              <i class="mdi mdi-star"></i>
                            </li>
                          </ul>
                        </h5>
                        <ul class="list-unstyled head mb-0">
                          <li
                            v-for="(_item, _index) in item.job_type"
                            :key="_index"
                            class="badge badge-success badge-pill"
                          >
                            {{ _item }}
                          </li>
                        </ul>
                      </div>

                      <div class="card-body content position-relative">
                        <div style="display: flex">
                          <ul class="job-facts list-unstyled">
                            <li class="list-inline-item text-muted">
                              <check-icon
                                class="fea icon-sm text-success mr-1"
                              ></check-icon>
                              {{ item.minimum_experience_years + " years" }}
                            </li>
                          </ul>
                          <ul
                            class="list-unstyled social-icon mb-0 mt-4"
                            style="width: 35%"
                          ></ul>
                        </div>
                        <button
                          v-on:click="singleDetail(item)"
                          class="btn btn-outline-primary btn-block"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end col-->
        </div>

        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->

    <!-- Modal Content Start -->
    <b-modal id="ApplyNow" title="Apply now" hide-footer body-class="p-4">
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group position-relative">
              <label>Your Name :<span class="text-danger">*</span></label>
              <user-icon class="fea icon-sm icons"></user-icon>
              <input
                name="name"
                id="name"
                type="text"
                class="form-control pl-5"
                required
                placeholder="First Name :"
              />
            </div>
          </div>
          <!--end col-->
          <div class="col-md-6">
            <div class="form-group position-relative">
              <label>Your Email :<span class="text-danger">*</span></label>
              <mail-icon class="fea icon-sm icons"></mail-icon>
              <input
                name="email"
                id="email"
                type="email"
                class="form-control pl-5"
                required
                placeholder="Your email :"
              />
            </div>
          </div>
          <!--end col-->
          <div class="col-md-6">
            <div class="form-group position-relative">
              <label>Your Phone no. :<span class="text-danger">*</span></label>
              <phone-icon class="fea icon-sm icons"></phone-icon>
              <input
                name="number"
                id="number"
                type="number"
                class="form-control pl-5"
                required
                placeholder="Your phone no. :"
              />
            </div>
          </div>
          <!--end col-->
          <div class="col-md-6">
            <div class="form-group position-relative">
              <label>Job Title :</label>
              <book-icon class="fea icon-sm icons"></book-icon>
              <input
                name="subject"
                id="subject"
                class="form-control pl-5"
                required
                placeholder="Title :"
              />
            </div>
          </div>
          <!--end col-->
          <div class="col-md-6">
            <div class="form-group">
              <label>Types of jobs :</label>
              <select class="form-control custom-select" id="Sortbylist-job">
                <option>All Jobs</option>
                <option>Full Time</option>
                <option>Half Time</option>
                <option>Remote</option>
                <option>In Office</option>
              </select>
            </div>
          </div>
          <!--end col-->
          <div class="col-md-12">
            <div class="form-group position-relative">
              <label>Description :</label>
              <message-circle-icon
                class="fea icon-sm icons"
              ></message-circle-icon>
              <textarea
                name="comments"
                id="comments"
                rows="4"
                class="form-control pl-5"
                required
                placeholder="Describe the job :"
              ></textarea>
            </div>
          </div>
          <!--end col-->
          <div class="col-md-12">
            <div class="form-group position-relative">
              <label>Upload Your Cv / Resume :</label>
              <input
                type="file"
                class="form-control-file"
                required
                id="fileupload"
              />
            </div>
          </div>
          <!--end col-->
          <div class="col-md-12">
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck1"
                />
                <label class="custom-control-label" for="customCheck1"
                  >I Accept
                  <a href="#" class="text-primary"
                    >Terms And Condition</a
                  ></label
                >
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <div class="row">
          <div class="col-sm-12">
            <input
              type="submit"
              id="submit"
              name="send"
              class="submitBnt btn btn-primary"
              value="Apply Now"
            />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </form>
      <!--end form-->
    </b-modal>
    <!-- Modal Content End -->
    <!-- Company Detail End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
